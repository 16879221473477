// MEMO: import this for IE11.
// MEMO: Swiper 5.4.5 works on IE11.
// import Swiper from "../../node_modules/swiper/js/swiper";

// import the latest version
import Swiper, {
  Navigation,
  Pagination,
  Autoplay,
  EffectCoverflow,
  EffectFade,
} from "swiper";

// configure Swiper to use modules
Swiper.use([Navigation, Pagination, Autoplay, EffectCoverflow, EffectFade]);

/*
 *
 * ----------------------------------------------- */
var swiperSimple = document.querySelector(".swiper-fv");

if (swiperSimple) {
  new Swiper(swiperSimple, {
    effect: "fade",
    fadeEffect: {
      crossFade: false,
    },
    speed: 2000,
    loop: true,
    autoplay: {
      delay: 4000,
      disableOnInteraction: false,
    },
    pagination: {
      el: ".swiper-pagination",
      clickable: true,
    },
    navigation: {
      nextEl: ".swiper-button-next",
      prevEl: ".swiper-button-prev",
    },
  });
}

jQuery(".swiper-pagination-bullet").each(function () {
  jQuery(this).append(
    '<svg height="22" width="22">\n' +
      '  <circle class="circle" cx="4" cy="4" r="11" stroke="#fff" stroke-width="2" fill-opacity="0" />\n' +
      "  </svg>"
  );
});

jQuery(".swiper-pagination-bullet svg").each(function () {
  jQuery(this).hide();
});

/*
 *
 * ----------------------------------------------- */
var swiperGallery = document.querySelector(".swiper-gallery");

if (swiperGallery) {
  var modalGallery = document.querySelectorAll(".modal-gallery");

  modalGallery.forEach((element) => {
    var swiperInstance = new Swiper(element.querySelector(".swiper-gallery"), {
      effect: "fade",
      fadeEffect: {
        crossFade: true,
      },
      speed: 1000,
      loop: true,
      autoHeight: true,
      navigation: {
        nextEl: ".swiper-button-next",
        prevEl: ".swiper-button-prev",
      },
    });

    element.addEventListener("shown.bs.modal", () => {
      swiperInstance.update();
    });
  });
}
